import { createRouter, createWebHistory } from "vue-router";
import {
  HomePage,
  LoginPage,
  RegisterPage,
  CoursePage,
  ArticlePage,
  PostBySlug,
  AuthorPage,
  ArticleByTag,
} from "@/views";

const routes = [
  { path: "/", component: HomePage },
  { path: "/connexion", component: LoginPage },
  { path: "/inscription", component: RegisterPage },
  { path: "/formations", component: CoursePage },
  { path: "/articles", component: ArticlePage },
  { path: "/article/:slug", component: PostBySlug },
  { path: "/author/:username", component: AuthorPage },
  { path: "/tag/:tag", component: ArticleByTag },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
