<template>
  <div
    tabindex="-1"
    @keyup.alt.r="isSearching = !isSearching"
    ref="searching"
    v-focus
  >
    <TheHeader v-model:isSearching="isSearching" />
    <router-view />
  </div>
</template>

<script>
import TheHeader from "@/components/TheHeader.vue";
import { focus } from "@/directives";

export default {
  directives: {
    focus,
  },
  data() {
    return {
      isSearching: false,
    };
  },
  components: {
    TheHeader,
  },
  watch: {
    isSearching() {
      this.$refs.searching.focus();
    },
  },
};
</script>

<style lang="scss">
$txt-dark: #00b3b3;
$txt-light: #008073;
$bg: #0d1f2d;

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
}

#app {
  height: 100vh;
}

body {
  padding: 0;
  margin: 0;
  font-family: Verdana, sans-serif;
  color: white;
  background-color: #0d1f2d;
}

div {
  outline: 0;
}

.main-container {
  padding: 15px;
  max-width: 1100px;
  margin: auto;
}
</style>
