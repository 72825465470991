<template>
  <div v-if="author">
    <div class="profile">
      <div class="profile__infos">
        <img src="@/assets/user.png" alt="" />
        <h2>{{ displayName }}</h2>
      </div>
      <div class="profile__bio">
        <p>{{ author.bio }}</p>
      </div>
    </div>

    <h3>Articles publiés par {{ displayName }}</h3>
    <ArticleList :articles="author.postSet" :showAuthor="false" />
  </div>
</template>

<script>
import { GET_AUTHOR_BY_USERNAME } from "@/api/queries";
import { ArticleList } from "@/components";

export default {
  name: "AuthorPage",
  data() {
    return {
      author: null,
    };
  },
  async created() {
    const user = await this.$apollo.query({
      query: GET_AUTHOR_BY_USERNAME,
      variables: {
        username: this.$route.params.username,
      },
    });
    this.author = user.data.authorByUsername;
  },
  computed: {
    displayName() {
      return (
        (this.author.user.firstName &&
          this.author.user.lastName &&
          `${this.author.user.firstName} ${this.author.user.lastName}`) ||
        `${this.author.user.username}`
      );
    },
  },
  components: {
    ArticleList,
  },
};
</script>

<style lang="scss" scoped>
.profile {
  display: flex;
  flex-direction: column;
  background-color: rgba(22, 52, 76, 0.3);
  width: 80%;
  margin: auto;
  padding: 15px;
  border-radius: 5px;

  .profile__infos {
    display: flex;
    align-items: center;
    font-size: 2rem;
    h2 {
      margin-left: 20px;
    }
  }

  .profile__bio {
    font-size: 1.6rem;
    padding: 25px;
  }
}
h3 {
  font-size: 2rem;
  padding: 20px;
}
</style>
