<template>
  <div class="search-bar-popup" @click="onClickHandler">
    <form autocomplete="off">
      <div class="search-field">
        <span class="material-icons"> search </span>
        <input
          list="results"
          type="search"
          placeholder="ALT + R"
          v-focus
          @keydown="$emit('onEscape', $event)"
        />
      </div>
      <ul class="search-results">
        <li
          :class="selected ? 'selected' : ''"
          class="search-result-item"
          :aria-selected="true"
        >
          <span class="search-result-category">Category</span>
          Example 1
        </li>
        <li class="search-result-item">Exemple 2</li>
        <li class="search-result-item">Exemple 3</li>
        <li class="default search-result-item">Aucun résultat</li>
        <div class="search-commands-infos">
          <p title="Appuyez sur entrer pour valider votre choix">
            <img src="@/assets/return.png" alt="" />
            Selectionner
          </p>
          <p title="Appuyer sur les flèches pour naviguer">
            <img src="@/assets/up-arrow.png" alt="" />
            <img src="@/assets/down-arrow.png" alt="" />
            Naviguer
          </p>
          <p title="Appuyer sur échappe pour quitter">
            <img src="@/assets/escape.png" alt="" />Fermer
          </p>
          <p
            class="icons-interogation"
            title="icon created by Pixel perfect - Flaticon https://www.flaticon.com/free-icons/escape"
          >
            <img src="@/assets/doubts-button.png" alt="question mark icon" />
          </p>
        </div>
      </ul>
    </form>
  </div>
</template>

<script>
import { focus } from "@/directives";

export default {
  directives: {
    focus,
  },
  methods: {
    onClickHandler(event) {
      const target = event.target;
      if (
        target.tagName === "DIV" &&
        target.classList.contains("search-bar-popup")
      ) {
        this.$emit("onClose");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
$txt-light: #00b3b3;
$txt-dark: #008073;
$bg: #0d1f2d;

.search-bar-popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;

  .search-field {
    position: relative;
    span {
      position: absolute;
      color: white;
      top: 50%;
      left: 3px;
      transform: translateY(-50%);
    }

    input {
      padding-top: 5px;
      padding-bottom: 5px;
      padding-left: 30px;
      width: 100%;
      background-color: $bg;
      border: none;
      border-radius: 5px;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
      color: rgb(197, 197, 197);
      height: 40px;
      font-size: 1.6rem;

      &:focus {
        outline: none;
        border: 1px solid $txt-dark;
        box-shadow: 0px 0px 5px $txt-light;
      }

      &::placeholder {
        text-align: right;
        color: #cac8c8;
        font-weight: bold;
      }
    }
  }
  form {
    position: fixed;
    left: auto;
    right: auto;
    top: 20px;
    width: min(calc(100% - 20px), 600px);
    padding: 8px;
    border-radius: 4px;
    margin-bottom: 40%;
    display: flex;
    flex-direction: column;

    .search-results {
      list-style: none;
      border-bottom-right-radius: 5px;
      border-bottom-left-radius: 5px;
      overflow: hidden;
    }

    .search-result-item {
      padding: 1rem;
      background-color: #0d1f2d;
      font-size: 1.6rem;
    }

    .selected,
    .search-result-item:not(.default):hover {
      background-color: $txt-light;
      text-shadow: 0px 0px 5px black;
      transition: 200ms;
    }

    .default {
      text-align: center;
      padding: 30px;
    }

    .search-commands-infos {
      display: none;
      align-items: center;
      background-color: #08141c;
      padding: 10px;
      img {
        width: 24px;
        margin-right: 3px;
      }
      p {
        display: flex;
        align-items: center;
        margin: 0 15px;
        font-size: 1.2rem;
      }

      .icons-interogation {
        margin-left: auto;
      }
    }
  }
}

@media screen and (min-width: 768px) {
  .search-bar-popup {
    form {
      .search-commands-infos {
        display: flex;
      }
    }
  }
}
</style>
