<template>
  <div class="main-container">
    <div class="header">
      <h1>Articles</h1>
      <button
        :class="isFiltering ? 'active' : ''"
        class="btn-filter"
        @click="isFiltering = !isFiltering"
      >
        <span class="material-icons"> filter_alt </span>Filtres
      </button>
    </div>
    <Transition>
      <form @submit.prevent="" class="filters" v-show="isFiltering">
        <fieldset>
          <legend>Niveaux</legend>
          <div class="form-group">
            <label for="beginner">Débutant</label>
            <div class="switch" @click="onSwitchHandler($event, 'beginner')">
              <input
                name="levels"
                type="checkbox"
                id="beginner"
                ref="beginner"
              />
              <span class="slider round"></span>
            </div>
          </div>
          <div class="form-group">
            <label for="intermediate">Intermediaire</label>
            <div
              class="switch"
              @click="onSwitchHandler($event, 'intermediate')"
            >
              <input
                name="levels"
                type="checkbox"
                id="intermediate"
                ref="intermediate"
              />
              <span class="slider round"></span>
            </div>
          </div>
          <div class="form-group">
            <label for="advanced">Avancé</label>
            <div class="switch" @click="onSwitchHandler($event, 'advanced')">
              <input
                name="levels"
                type="checkbox"
                id="advanced"
                ref="advanced"
              />
              <span class="slider round"></span>
            </div>
          </div>
        </fieldset>
        <fieldset>
          <legend>Catégories</legend>
          <div class="form-group">
            <label for="django">Django</label>
            <div class="switch" @click="onSwitchHandler($event, 'django')">
              <input
                type="checkbox"
                class="slider round"
                id="django"
                ref="django"
              />
              <span class="slider round"></span>
            </div>
          </div>
          <div class="form-group">
            <label for="python">Python</label>
            <div class="switch" @click="onSwitchHandler($event, 'python')">
              <input
                type="checkbox"
                class="slider round"
                id="python"
                ref="python"
              />
              <span class="slider round"></span>
            </div>
          </div>
          <div class="form-group">
            <label for="javascript">Javascript</label>
            <div class="switch" @click="onSwitchHandler($event, 'javascript')">
              <input
                type="checkbox"
                class="slider round"
                id="javascript"
                ref="javascript"
              />
              <span class="slider round"></span>
            </div>
          </div>
          <div class="form-group">
            <label for="git">Git</label>
            <div class="switch" @click="onSwitchHandler($event, 'git')">
              <input type="checkbox" class="slider round" id="git" ref="git" />
              <span class="slider round"></span>
            </div>
          </div>
        </fieldset>
        <button
          type="reset"
          title="Reset icons created by Freepik - Flaticon https://www.flaticon.com/free-icons/reset"
        >
          <img src="@/assets/reset.png" alt="" />
        </button>
      </form>
    </Transition>

    <TheLoader v-if="isLoading" />
    <ArticleList :articles="articles" v-else />
  </div>
</template>

<script>
import { ArticleList, TheLoader } from "@/components";
import { GET_ALL_POSTS } from "@/api/queries";

export default {
  async created() {
    this.isLoading = true;
    const articles = await this.$apollo.query({ query: GET_ALL_POSTS });
    this.articles = articles.data.allPosts;
    this.isLoading = false;
  },
  data() {
    return {
      isLoading: false,
      isFiltering: false,
      articles: [],
    };
  },
  methods: {
    onSwitchHandler(event, refName) {
      const refInput = this.$refs[refName];
      refInput.checked = !refInput.checked;
      if (event.target.type === "checkbox") {
        const target = event.target;
        const checked = target.checked;
        target.checked = !checked;
      }
    },
  },
  components: { ArticleList, TheLoader },
};
</script>

<style lang="scss" scoped>
$txt-light: #00b3b3;
$txt-dark: #008073;
$bg: #0d1f2d;

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: auto;
  padding: 15px;
  max-width: 700px;
  width: 80%;
}

h1 {
  text-align: center;
}

button.btn-filter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 8px;
  padding-left: 3px;
  border: 1px solid white;
  border-radius: 5px;
  color: white;
  background-color: transparent;
  letter-spacing: 1px;

  &.active {
    border: 1px solid $txt-light;
    color: $txt-light;
    background-color: rgba(24, 55, 80, 0.6);
  }
}

form.filters {
  display: flex;
  flex-direction: column;
  padding: 10px 5px;
  border-radius: 5px;
  margin-bottom: 15px;
  fieldset {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    border: none;
    font-size: 1.3rem;
    border: 1px solid rgb(128, 128, 128, 0.4);
    border-radius: 5px;
    legend {
      font-weight: bold;
      padding: 3px;
    }
  }
  .form-group {
    display: flex;
    flex: 1;
    flex-direction: row-reverse;
    justify-content: flex-end;
    text-align: right;
    align-items: center;
    padding: 8px;

    label {
      margin-left: 3px;
    }
  }

  .switch {
    position: relative;
    display: inline-block;
    width: 45px;
    height: 25px;
  }

  .switch input {
    opacity: 0;
    width: 1px;
    height: 1px;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 19px;
    width: 19px;
    left: 3px;
    bottom: 3px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider {
    background-color: $txt-light;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px $txt-light;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(20px);
    -ms-transform: translateX(20px);
    transform: translateX(20px);
  }

  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }

  button[type="reset"] {
    padding: 5px;
    background: transparent;
    border: none;
    margin: 15px auto;
    img {
      width: 40px;
    }
    &:active {
      transform: scale(0.9);
    }
  }
}

.v-enter-active,
.v-leave-active {
  transition: opacity 150ms ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

@media screen and (min-width: 615px) {
  form.filters fieldset {
    width: 80%;
    max-width: 700px;
    margin: auto;
  }
}
</style>
