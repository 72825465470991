import { createApolloProvider } from "@vue/apollo-option";
import { URL } from "./secret";
import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
} from "@apollo/client/core";

const httpLink = createHttpLink({
  uri: URL,
  // fetchOptions: { mode: "no-cors" },
});

const cache = new InMemoryCache();

const apolloClient = new ApolloClient({
  link: httpLink,
  cache,
});

export default createApolloProvider({
  defaultClient: apolloClient,
});
