<template>
  <header>
    <nav>
      <div>
        <router-link to="/" id="home">
          <div class="img-container">
            <img src="@/assets/decodex_full.svg" alt="iyed logo" />
          </div>
        </router-link>
      </div>
      <ul class="menu">
        <div class="list-items">
          <li>
            <router-link to="/formations" id="courses">
              <span class="material-icons"> school </span>Formations
            </router-link>
          </li>
          <li>
            <router-link to="/articles" id="blog">
              <span class="material-icons"> library_books </span>Articles
            </router-link>
          </li>
          <li>
            <button @click="onClickHandler">
              <span class="material-icons"> search </span>Rechercher
            </button>
          </li>
        </div>
        <div class="buttons">
          <li class="btn btn-login">
            <router-link to="/connexion" id="login">Connexion</router-link>
          </li>
          <li class="btn btn-register">
            <router-link to="/inscription" id="register">
              Inscription
            </router-link>
          </li>
        </div>
      </ul>
      <ul class="menu-small-device">
        <li @click="onClickHandler">
          <span class="material-icons" id="search-icon"> search </span>
        </li>
        <li>
          <span class="material-icons" id="menu-icon"> menu </span>
        </li>
      </ul>
    </nav>
  </header>
  <Transition>
    <TheSearchBar
      v-if="isSearching"
      @onEscape="closeModal"
      @onClose="$emit('update:isSearching', false)"
    />
  </Transition>
</template>

<script>
import { TheSearchBar } from "@/components";
export default {
  props: ["isSearching"],
  emits: ["update:isSearching"],
  components: {
    TheSearchBar,
  },
  methods: {
    onClickHandler() {
      this.$emit("update:isSearching", true);
    },
    closeModal(event) {
      console.log("Event: ", event);
      if (event.keyCode === 27) this.$emit("update:isSearching", false);
    },
  },
};
</script>

<style lang="scss" scoped>
$txt-dark: #00b3b3;
$txt-light: #008073;
$bg: #0d1f2d;

header {
  padding: 1px;
  box-shadow: 0 0 5px #0d1f2d;
  height: 5vh;
  margin-bottom: 20px;
  .menu {
    display: none;
  }

  .menu-small-device {
    display: flex;
    list-style: none;
    li {
      margin: 0 5px;
    }
  }
  nav {
    padding: 0 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
  }

  .img-container {
    img {
      width: 100%;
      object-fit: cover;
    }
  }

  a {
    color: white;
    text-decoration: none;
  }
}

.v-enter-active,
.v-leave-active {
  transition: opacity 150ms ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

@media screen and (min-width: 768px) {
  header {
    height: 10vh;
    .menu-small-device span.material-icons {
      font-size: 4rem;
    }
  }
}

@media screen and (min-width: 1023px) {
  header {
    display: flex;
    align-items: center;

    .menu {
      display: flex;
      flex-grow: 2;

      div {
        margin: 0 30px;
      }
    }

    .menu-small-device {
      display: none;
    }

    nav {
      font-size: 1.6rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      ul {
        display: flex;
        flex-grow: 2;
        justify-content: space-between;
        list-style: none;
        margin: 0;
        padding-left: 15px;
      }

      div {
        display: flex;
      }

      li a,
      li button {
        display: flex;
        align-items: center;
        padding: 5px;
        margin: 0 10px;

        span {
          color: #00b3b3;
          margin-right: 5px;
        }
      }

      li button {
        border: none;
        background: none;
        color: white;
        font-family: Verdana, sans-serif;
        font-size: 1.6rem;
      }

      .search-bar {
        position: relative;
        input::placeholder {
          text-align: right;
          color: #008080;
          margin-right: 10px;
          font-weight: bold;
        }

        input:focus {
          outline-color: #00b3b3;
        }
        input {
          border-radius: 5px;
          border: none;
          padding-left: 27px;
          display: none;
        }
        span {
          color: white;
        }
      }

      .buttons {
        li {
          display: flex;
          align-items: center;
          border: 1px solid $bg;
          border-radius: 5px;
          a {
            color: #00b3b3;
          }
          &:hover {
            border: 1px solid $txt-dark;
          }
        }
      }
    }
  }
}
</style>
