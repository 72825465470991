<template>
  <TheLoader v-if="isLoading" />
  <template v-else>
    <div class="article" v-if="article">
      <h1 class="article__title">{{ article.title }}</h1>
      <h2 class="article__subtitle">{{ article.subtitle }}</h2>
      <div class="article__dateauthor">
        <p>Mis à jour le {{ articleUpdatedAtLocal }}</p>
        <p>Publié par <AuthorLink :author="article.author" /></p>
      </div>
      <p class="article__description">{{ article.metaDescription }}</p>
      <hr />
      <article>
        <ArticleContent :content="getCodeEditor" />
      </article>
      <ul class="article__tags">
        <li class="article__tag" v-for="tag in article.tags" :key="tag.name">
          <router-link :to="`/tag/${tag.name}`">#{{ tag.name }}</router-link>
        </li>
      </ul>
    </div>
    <div v-else class="no-article">
      <h2>Aucun article n'a été trouvé</h2>
    </div>
  </template>
</template>

<script>
import AuthorLink from "@/components/AuthorLink";
import { GET_POST_BY_SLUG } from "@/api/queries";
import { ArticleContent, TheLoader } from "@/components";

export default {
  name: "PostBySlug",
  components: {
    TheLoader,
    AuthorLink,
    ArticleContent,
  },
  data() {
    return {
      article: null,
      isLoading: false,
    };
  },
  async created() {
    this.isLoading = true;
    const article = await this.$apollo.query({
      query: GET_POST_BY_SLUG,
      variables: { slugTitle: this.$route.params.slug },
    });
    this.article = article.data.postBySlug;
    this.isLoading = false;
  },
  methods: {
    displayableDate(date) {
      return new Intl.DateTimeFormat("fr-FR", { dateStyle: "full" }).format(
        new Date(date)
      );
    },
  },
  computed: {
    getCodeEditor() {
      const htmlContent = this.article.content
        .replaceAll("&lt;", "<")
        .replaceAll("&gt;", ">")
        .replaceAll("&quot;", '"')
        .replaceAll("&#39;", "'")
        .replaceAll("&nbsp;", " ");
      return htmlContent;
    },
    articleUpdatedAt() {
      return new Date(this.article.updatedAt);
    },
    articleUpdatedAtLocal() {
      return this.articleUpdatedAt.toLocaleDateString();
    },
  },
};
</script>

<style lang="scss" scoped>
$txt-light: #00b3b3;
$txt-dark: #008073;
$bg: #0d1f2d;

.no-article {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 2rem;
}

.article {
  font-size: 2rem;
  max-width: 1200px;
  margin: auto;
  padding: 15px;

  .article__title {
    font-size: 3.5rem;
    margin: 15px auto;
  }

  .article__subtitle {
    margin: 10px auto;
    font-size: 2rem;
  }

  .article__dateauthor {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    font-size: 1.6rem;
  }

  .article__tag {
    display: inline-block;
    background-color: $txt-dark;
    padding: 3px 15px;
    border-radius: 15px;
    margin-top: 15px;

    a {
      color: $bg;
      text-decoration: none;
    }
  }
  hr {
    width: 80%;
    margin: 15px auto;
  }

  .loader {
    margin: auto;
  }
}
</style>
