<template>
  <router-link :to="`/author/${displayName}`">{{ displayName }}</router-link>
</template>

<script>
export default {
  name: "AuthorLink",
  props: {
    author: {
      type: Object,
      required: true,
    },
  },
  computed: {
    displayName() {
      return (
        (this.author.user.firstName &&
          this.author.user.lastName &&
          `${this.author.user.firstName} ${this.author.user.lastName}`) ||
        `${this.author.user.username}`
      );
    },
  },
};
</script>

<style lang="scss" scoped>
$txt-light: #00b3b3;
$txt-dark: #008073;
a {
  text-decoration: none;
  color: $txt-light;
  font-weight: bold;
}
</style>
