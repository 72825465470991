<template>
  <WaitV1 pageName="inscription" />
</template>

<script>
import { WaitV1 } from "@/components";

export default {
  components: {
    WaitV1,
  },
};
</script>

<style scoped>
h1 {
  text-align: center;
}
</style>
