<template>
  <WaitV1 pageName="formations" />
</template>

<script>
import { WaitV1 } from "@/components";

export default {
  components: {
    WaitV1,
  },
};
</script>

<style></style>
