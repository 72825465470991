<template>
  <div></div>
</template>

<script>
import { compile } from "vue/dist/vue.esm-bundler.js";
import CodeEditor from "simple-code-editor";

export default {
  name: "ArticleContent",
  components: {
    /*eslint-disable */
    CodeEditor,
    /*eslint-enable */
  },
  props: ["content"],
  setup(props) {
    return compile(props.content);
  },
};
</script>

<style lang="scss">
$txt-light: #00b3b3;
$txt-dark: #008073;

article {
  display: flex;
  flex-direction: column;
  h2 {
    text-decoration: underline;
    margin: 15px auto;
  }

  a {
    overflow-wrap: anywhere;
    text-decoration: none;
    color: $txt-dark;
  }

  p {
    margin: 15px 0;
  }
  .img-container {
    min-width: 290px;
    width: 80%;
    margin: auto;
    img {
      width: 100%;
      object-fit: contain;
    }
  }
}
</style>
