<template>
  <div class="img-container loader">
    <img src="@/assets/loader.gif" alt="content is loading" />
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "TheLoader",
});
</script>

<style lang="scss" scoped>
.img-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 128px;
  margin: auto;
}

img {
  width: 100%;
  height: 100%;
}
</style>
