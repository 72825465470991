import { gql } from "@apollo/client/core";

export const GET_ALL_POSTS = gql`
  query {
    allPosts {
      title
      subtitle
      publishedAt
      updatedAt
      published
      metaDescription
      slugTitle
      level
      author {
        user {
          username
          firstName
          lastName
        }
      }
      tags {
        name
      }
    }
  }
`;

export const GET_POST_BY_SLUG = gql`
  query ($slugTitle: String!) {
    postBySlug(slugTitle: $slugTitle) {
      title
      subtitle
      published
      publishedAt
      createdAt
      updatedAt
      metaDescription
      slugTitle
      content
      level
      author {
        user {
          username
          firstName
          lastName
        }
      }
      tags {
        name
      }
    }
  }
`;

export const GET_AUTHOR_BY_USERNAME = gql`
  query ($username: String!) {
    authorByUsername(username: $username) {
      bio
      user {
        username
      }
      postSet {
        title
        subtitle
        published
        publishedAt
        metaDescription
        slugTitle
        level
        tags {
          name
        }
      }
    }
  }
`;

export const GET_POST_BY_TAG = gql`
  query ($tag: String!) {
    postsByTag(tag: $tag) {
      title
      subtitle
      published
      publishedAt
      createdAt
      updatedAt
      metaDescription
      slugTitle
      level
      author {
        user {
          username
        }
      }
      tags {
        name
      }
    }
  }
`;
