<template>
  <div class="articles">
    <ArticleDetail
      v-for="article of publishedArticles"
      :key="article.id"
      :article="article"
      :showAuthor="showAuthor"
    />
  </div>
</template>

<script>
import ArticleDetail from "./ArticleDetail";
export default {
  props: {
    articles: {
      type: Array,
      required: true,
    },
    showAuthor: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  methods: {
    strToDate: function (value) {
      return new Date(value);
    },
    getFrLevel: function (value) {
      switch (value) {
        case "BEGINNER":
          return "Débutant";
        case "INTERMEDIATE":
          return "Intermédiaire";
        case "ADVANCED":
          return "Avancé";
        default:
          return "Pas de niveau";
      }
    },
  },
  computed: {
    publishedArticles() {
      return this.articles.filter((article) => article.published);
    },
  },
  components: { ArticleDetail },
};
</script>

<style lang="scss" scoped>
.articles {
  display: grid;
  grid-template-columns: repeat(auto-fill, 290px);
  justify-content: center;
  gap: 45px 30px;
}
</style>
