<template>
  <div>
    <h2>
      Articles avec le tag <span>#{{ $route.params.tag }}</span>
    </h2>
    <ArticleList :articles="articles" v-if="articles" />
  </div>
</template>

<script>
import { GET_POST_BY_TAG } from "@/api/queries";
import ArticleList from "@/components/ArticleList";

export default {
  name: "ArticleByTag",
  data() {
    return {
      articles: null,
    };
  },
  async created() {
    const articles = await this.$apollo.query({
      query: GET_POST_BY_TAG,
      variables: {
        tag: this.$route.params.tag,
      },
    });
    this.articles = articles.data.postsByTag;
  },
  components: {
    ArticleList,
  },
};
</script>

<style scoped>
h2 {
  padding: 20px;
  font-size: 1.8rem;
}
</style>
