<template>
  <div v-if="article" class="article" @click="null">
    <div class="article__img">
      <img
        src="https://via.placeholder.com/290x170"
        alt="placeholder picture"
      />
    </div>
    <div class="article__text">
      <p class="article__tag" :class="article.level.toLowerCase()">
        {{ getFrLevel(article.level) }}
      </p>
      <h2>
        <router-link
          :to="`/article/${article.slugTitle}`"
          :title="`Go to ${article.title}`"
        >
          {{ article.title }}
        </router-link>
      </h2>
      <div class="article__details">
        <p class="article__details__subtitle">
          {{ article.subtitle }}
        </p>
        <div>
          <p>{{ strToDate(article.publishedAt).toLocaleDateString() }}</p>
          <p v-if="showAuthor">
            écrit par
            <AuthorLink :author="article.author" />
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AuthorLink from "@/components/AuthorLink";

export default {
  name: "ArticleDetail",
  props: {
    article: {
      type: Object,
      required: true,
    },
    showAuthor: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  methods: {
    strToDate: function (value) {
      return new Date(value);
    },
    getFrLevel: function (value) {
      switch (value) {
        case "BEGINNER":
          return "Débutant";
        case "INTERMEDIATE":
          return "Intermédiaire";
        case "ADVANCED":
          return "Avancé";
        default:
          return "Pas de niveau";
      }
    },
  },
  components: {
    AuthorLink,
  },
};
</script>

<style lang="scss" scoped>
.article {
  width: 290px;
  padding: 3px;
  border-radius: 5px;
  background-color: rgba(22, 52, 76, 0.3);
  box-shadow: 0px 0px 15px black;
  h2 a {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.8rem;
    height: 70px;
    padding-bottom: 15px;
    text-decoration: none;
    color: white;
  }
  .article__img {
    max-width: 290px;
    margin: auto;
    img {
      width: 100%;
      object-fit: cover;
    }
  }
  .article__tag {
    width: 130px;
    text-align: center;
    font-size: 1.3rem;
    margin: auto;
    margin-bottom: 15px;
    border-radius: 6px;
    padding: 5px;
    font-weight: bold;
    &.beginner {
      color: #03e0e0;
      background-color: rgba(15, 81, 50, 0.6);
      color: #03e065;
      background-color: rgb(10 102 73 / 50%);
    }
    &.intermediate {
      color: rgb(250, 158, 0);
      background-color: rgba(134, 86, 3, 0.6);
    }
    &.advanced {
      background-color: rgba(239, 68, 68, 0.3);
      color: rgb(250, 107, 107);
    }
  }
  .article__text {
    padding: 15px;
    h2 a,
    p {
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .article__details {
      font-size: 1.4rem;
      .article__details__subtitle {
        height: 55px;
      }
      div {
        display: flex;
        justify-content: space-between;
        padding-top: 15px;
        p {
          color: #74b7ed;
        }
      }
    }
  }
}
</style>
