<template>
  <div class="container">
    <h2>
      Malheureusement, la page <mark>{{ pageName }}</mark> n'est pas encore
      disponible.
    </h2>
    <h3>Ne vous en faite pas, la <mark>v1</mark> arrive très bientôt.</h3>
  </div>
</template>

<script>
export default {
  props: ["pageName"],
};
</script>

<style lang="scss" scoped>
$txt-dark: #00b3b3;
$txt-light: #008073;
$bg: #0d1f2d;
$header-small-size: 5vh;
$header-size: 10vh;

.container {
  position: absolute;
  top: calc(50% - $header-small-size);
  left: 50%;
  transform: translate(-50%, (calc(-50% + $header-small-size)));
  color: $txt-dark;
  padding: 15px;
}

mark {
  background-color: transparent;
  color: white;
}

h2 {
  font-size: 2rem;
}
h3 {
  margin-top: 25px;
  font-size: 1.5rem;
}

@media screen and (min-width: 768px) {
  .container {
    top: calc(50% - $header-size);
    left: 50%;
    transform: translate(-50%, (calc(-50% + $header-size)));
  }
  h2 {
    font-size: 3rem;
  }
  h3 {
    font-size: 2rem;
  }
}
</style>
