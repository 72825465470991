<template>
  <main>
    <div class="container">
      <div class="infos">
        <h1>Formez vous et devenez un <span>professionnel</span></h1>
        <p>
          Apprenez à maitriser <b>Django</b>/<b>VueJS</b>, et devenez un
          véritable professionnel avec les différents niveaux de formation:
          <br />
          <span class="beginner">débutant</span>,
          <span class="intermediate">intermédiaire</span>,
          <span class="advanced">avancé</span>.
        </p>
        <div class="buttons">
          <div class="btn-blog">
            <router-link to="/articles"> Lire les articles </router-link>
          </div>
          <div class="btn-courses">
            <router-link to="/formations"> Voir les formations </router-link>
          </div>
        </div>
      </div>
      <div class="img-container">
        <img src="@/assets/mascotte.png" alt="mascotte" />
      </div>
    </div>
  </main>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
$txt-light: #00b3b3;
$txt-dark: #008073;
$header-small-size: 5vh;
$header-size: 15vh;

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  min-height: calc(100vh - $header-small-size);
  max-width: 1300px;
  margin: auto;
}

h1 {
  font-size: 3rem;
  padding: 10px;
  span {
    font-size: 3.2rem;
    color: $txt-light;
  }
}

p {
  padding: 1rem;
  font-size: 1.3rem;
}

.infos {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 8px;
  text-align: center;
  h1 {
    margin-top: 15px;
  }

  p,
  div {
    margin-top: 5px;
  }
}

.buttons {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  a {
    display: inline-block;
    padding: 8px 10px;
    font-size: 1.4rem;
    text-align: center;
    text-decoration: none;
    color: white;
    border-radius: 5px;
    margin: 7px;
    width: 170px;
  }
  div {
    text-align: center;
  }
  .btn-blog a {
    background-color: $txt-light;
    color: white;
  }

  .btn-blog a:hover {
    background-color: $txt-dark;
    color: white;
  }

  .btn-courses a {
    border: 1px solid $txt-light;
    color: $txt-light;
    transition: 300ms;
  }

  .btn-courses a:hover {
    background-color: $txt-light;
    color: white;
  }
}

.img-container {
  max-width: 280px;
  margin: auto;
  img {
    width: 100%;
    object-fit: cover;
  }
}

.beginner,
.intermediate,
.advanced {
  font-weight: bold;
  text-transform: capitalize;
}

.beginner {
  color: lightgreen;
}

.intermediate {
  color: orange;
}

.advanced {
  color: rgb(241, 85, 85);
}

@media screen and (min-width: 425px) {
  .container {
    text-align: center;
    p {
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }
}

@media screen and (min-width: 500px) {
  h1 {
    font-size: 3.2rem;
  }
  p {
    font-size: 1.5rem;
  }
}

@media screen and (min-width: 768px) {
  .container {
    min-height: calc(100vh - $header-size);
  }

  h1 {
    font-size: 4rem;
    span {
      font-size: 4.2rem;
    }
  }
  p {
    font-size: 2rem;
    max-width: 90%;
    margin: auto;
  }

  .buttons a {
    padding: 15px 20px;
    font-size: 1.8rem;
    margin: 15px;
    width: 250px;
    font-weight: bold;
  }

  .img-container {
    max-width: 380px;
  }
}

@media screen and (min-width: 1024px) {
  .container {
    flex-direction: row;
  }
  .infos {
    justify-content: center;
  }
  .img-container {
    min-width: 300px;
  }
}
</style>
